import React from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'

const SLACK_APP_ID = process.env.SLACK_APP_ID

const SecondPage = props => {
  return (
    <Layout>
      <Container>
        <h1>🎉 Done! 🎉</h1>

        <p>Toast just DMed you on-boarding instructions</p>
        <a href={`https://slack.com/app_redirect?app=${ SLACK_APP_ID }`}>
          See it in Slack
        </a>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default SecondPage
